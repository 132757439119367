/* eslint-disable react/no-unknown-property */ 

import { ChatBubbleOutline, ChatBubble, Close } from "@material-ui/icons";
import { Session, SignalEvent } from "openvidu-browser-v2compatibility";
import { useEffect, useState, useRef } from "react";

import { IMessageHistory } from "shared/interfaces";
import { saveMessageIntoHistory } from "services/legacyService";
import { Roles, Signals } from "shared/enums";
import { Button, Grow } from "@material-ui/core";
import "./ChatMessageComponent.css";

export default function ChatMessageComponent(props: { session: Session }) {
  const [hasUnreadMessages, setUnreadMessages] = useState(false);
  const [messages, setMessages] = useState<IMessageHistory[]>([]);
  const [isActiveChat, setIsActiveChat] = useState(false);
  const [isDisplayed, setIsDisplayed] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [subscribed, setSubscribed] = useState(false);
  const [input, setInput] = useState("");

  const { session } = props;

  function scrollIntoView() {
    if (messagesEndRef.current) {
      messagesEndRef.current?.scrollIntoView({
        behavior: "smooth",
      });
    }
  }

  const saveMessageInRepo = async (msg: IMessageHistory) => {
    await saveMessageIntoHistory(
      {
        origin: msg.origin,
        texto: `${msg.origin} ${msg.text}`,
        date: msg.date,
        read: msg.read,
      },
      session.sessionId
    );
  };

  function sendMessage(e?: any) {
    setIsActiveChat(true);
    const messageFromAnalyst: IMessageHistory = {
      read: false,
      text: input,
      date: new Date(),
      origin: Roles.Analyst,
    };

    session.signal({
      data: messageFromAnalyst.text,
      type: Signals.MessagingInsured,
    });

    console.log({
      data: messageFromAnalyst.text,
      type: Signals.MessagingInsured,
    });

    setMessages((messages) => [...messages, messageFromAnalyst]);
    saveMessageInRepo(messageFromAnalyst);
    setIsActiveChat(false);
    setInput("");
  }

  function onToggleChat(e?: any) {
    setIsDisplayed(!isDisplayed);
  }

  function onEnterKeyPress(e: any) {
    if (e.key === "Enter" && isActiveChat) {
      sendMessage();
    }
    if (e.key === "Enter") {
      e.preventDefault();
    }
  }

  function onInputKeyPress(e: any) {
    setInput((e.target as HTMLTextAreaElement).value);
  }

  useEffect(() => {
    input.length === 0 ? setIsActiveChat(false) : setIsActiveChat(true);
  }, [input]);

  useEffect(() => {
    setUnreadMessages(false);
  }, [isDisplayed]);

  useEffect(() => {
    messages.find((i) => i.origin === Roles.Insured && i.read === false)
      ? setUnreadMessages(true)
      : setUnreadMessages(false);
  }, [messages]);

  useEffect(() => {
    scrollIntoView();
  }, [messages]);

  useEffect(() => {
    if (session && !subscribed) {
      setSubscribed(true);
      session.on(`signal:${Signals.MessagingAnalyst}`, (event: SignalEvent) => {
        console.log({ useEffect: event });
        setIsActiveChat(true);

        const messageFromInsured: IMessageHistory = {
          read: isDisplayed ? true : false,
          origin: Roles.Insured,
          text: event.data!,
          date: new Date(),
        };

        setMessages((messages) => [...messages, messageFromInsured]);
        saveMessageInRepo(messageFromInsured);
        setIsActiveChat(false);
      });
    }
  }, [session, subscribed]);

  return (
    <div>
      {isDisplayed ? (
        <Grow in={isDisplayed} style={{ transformOrigin: "0 0 0" }} {...(isDisplayed ? { timeout: 400 } : {})}>
          <div className='messages'>
            <div className='messagesTitle'>
              <label>Suporte à Sessão Ativa</label>
              <Close onClick={() => onToggleChat()} />
            </div>
            <div className='messagesContent'>
              {messages.map((i, k) => {
                return i.origin === Roles.Analyst ? (
                  <p
                    className='messageText analystMessage'
                    key={k}
                    // ref={messagesEndRef}
                  >
                    {i.text}
                  </p>
                ) : (
                  <p
                    className='messageText insuredMessage'
                    key={k}
                    // ref={messagesEndRef}
                  >
                    {i.text}
                  </p>
                );
              })}
            </div>
            <div className='messageBox'>
              <div className='messagesInput'>
                <textarea typeof='text' value={input} onKeyDown={onEnterKeyPress} onInput={onInputKeyPress} />
              </div>
              <div className='messagesControl'>
                <Button
                  className='messagesControl'
                  disabled={!isActiveChat}
                  onClick={sendMessage}>
                  Enviar
                </Button>
              </div>
            </div>
          </div>
        </Grow>
      ) : (
        <div className='chatIcon'>
          {hasUnreadMessages ? (
            <ChatBubble onClick={() => onToggleChat()} />
          ) : (
            <ChatBubbleOutline onClick={() => onToggleChat()} />
          )}
        </div>
      )}
    </div>
  );
}
