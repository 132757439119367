import { useHotkeys } from "react-hotkeys-hook";
import { AddAPhoto } from "@material-ui/icons";
import { v4 } from "uuid";
import { useEffect, useState } from "react";
import { Signals } from "shared/enums";
import { Button, LinearProgress, Box, Typography } from "@material-ui/core";
import { CaptureAsset } from "shared/interfaces";
import { SignalEvent } from "openvidu-browser-v2compatibility";
import { APP_DOMAIN } from "shared/constants";

interface CaptureButtonProps {
  sessionId: string;
  publisher: any;
}

export default function CaptureButton({ sessionId, publisher }: CaptureButtonProps) {
  const [currentCapture, setCurrentCapture] = useState<CaptureAsset | null>(null);

  useEffect(() => {
    function subscribeToSignedURLs() {
      publisher.session.on(`signal:room:${sessionId}:signedURL`, (event: SignalEvent) => {
        console.info(`Signed URL received for capture`, { event });

        // currentCapture!.isConfirmed = true;

        // setTimeout(() => {
        //   setCurrentCapture(null);
        // },1500);

        window.parent.postMessage({ func: "loadGallery", message: sessionId }, APP_DOMAIN!);
      });
    }

    subscribeToSignedURLs();

    return () => {
      publisher.session.off(`signal:room:${sessionId}:signedURL`);
    };
  }, [sessionId, publisher.session]);

  function createImageRequest() {
    const newCapture: CaptureAsset = {
      id: v4(),
      progress: 0,
      hasThumb: false,
      isFailed: false,
      isConfirmed: false,
      isSubscribed: true,
      createdAt: new Date(),
    };

    // setCurrentCapture(newCapture);

    publisher.session.signal({
      data: newCapture.id,
      type: Signals.ScreenShot,
    });

    return newCapture;
  }

  function subscribeToConfirmation(capture: CaptureAsset) {
    publisher.session.on(`signal:${Signals.ScreenShot}:${capture.id}`, (event: SignalEvent) => {
      console.info(`Frame ${event.data} confirmed`, { event, now: new Date() });

      // const randomProgress = Math.floor(Math.random() * (80 - 20 + 1)) + 20;

      // let currentProgress = randomProgress;
      // const interval = setInterval(() => {
      //   currentProgress += Math.random() * 3;

      //   currentCapture!.progress = currentProgress;
      //   setCurrentCapture({ ...currentCapture! });

      //   if (currentProgress >= 100) clearInterval(interval);
      // }, 500);
    });
  }

  function subscribeToException(id: string) {
    publisher.session.on(`signal:${Signals.ScreenShot}:${id}:failed`, () => {
      console.info(`Frame ${id} failed`);
      // currentCapture!.isFailed = true;
      // setCurrentCapture({ ...currentCapture! });

      // setTimeout(() => {
      //   setCurrentCapture(null);
      // }, 1500);
    });
  }

  function remoteCapturePhotoFromStream() {
    const newCapture = createImageRequest();
    subscribeToException(newCapture.id);
    subscribeToConfirmation(newCapture);
  }

  useHotkeys("shift+c", remoteCapturePhotoFromStream);

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Button
        color="primary"
        title="CAPTURAR"
        variant="contained"
        className="navButton"
        id="navCaptureButton"
        startIcon={<AddAPhoto />}
        onClick={remoteCapturePhotoFromStream}
      />
        {/* {currentCapture && (
          <Box key={currentCapture.id} width="100%" textAlign="center">
            {!currentCapture.isConfirmed && !currentCapture.isFailed && (
              <Box display="flex" alignItems="center" mt={1}>
                <LinearProgress variant="determinate" value={currentCapture.progress} style={{ flexGrow: 1 }} />
                <Box minWidth={35} ml={1}>
                  <span>{`${currentCapture.progress.toFixed(0)}%`}</span>
                </Box>
              </Box>
            )}
            {currentCapture.isFailed && (
              <Typography style={{ color: "red", opacity: 1, transition: "opacity 1s" }}>
                Falha na Captura
              </Typography>
            )}
            {currentCapture.isConfirmed && (
              <Typography style={{ color: "green", opacity: 1, transition: "opacity 1s" }}>
                Captura Confirmada
              </Typography>
            )}
          </Box>
        )} */}
    </Box>
  );
}
