import "./Participant.css";
import { Roles } from "../../shared/enums";
import { StreamManager } from "openvidu-browser-v2compatibility";
import { MediaStreamComponent } from "../MediaStreamComponent/DisplayMediaStream";

export default function SubscriberComponent(props: { subscriber: StreamManager }) {
  return (
    <div className='remote-media'>
      <MediaStreamComponent title={Roles.Insured} key={props.subscriber.id} mediaStream={props.subscriber} />
    </div>
  );
}
