import {
  Session,
  Publisher,
  SignalEvent,
  StreamEvent,
  StreamManager,
  StreamManagerEvent,
} from "openvidu-browser-v2compatibility";

import { SessionStatus, Signals } from "shared/enums";
import { reloadContext } from "services/legacyService";
import { useEffect, useState } from "react";

export default function useRTCEvents(props: { sessionId: string }) {
  const [subscribers, setSubscribers] = useState<StreamManager[]>([]);
  const [sessionStatus, setSessionStatus] = useState<SessionStatus>(SessionStatus.Awaiting);

  function subscribeToPublisherEvents(session: Session, publisher: Publisher) {
    publisher.on("accessDialogOpened", (e: StreamEvent) => {
      console.info("access dialog opened", e);
      sendSignalUserChanged(session, { accessDialogOpened: true }, Signals.UserChanged);
    });

    publisher.on("accessDialogClosed", () => {
      console.info("access dialog closed");
      sendSignalUserChanged(session, { accessDialogClosed: true }, Signals.UserChanged);
    });

    publisher.on("accessAllowed", () => {
      console.log(`[accessAllowed] ${props.sessionId}`);
      sendSignalUserChanged(session, { accessAllowed: true }, Signals.UserChanged);
    });

    publisher.on("accessDenied", () => {
      console.info("denied access to device camera");
      console.log(`[accessDenied] ${props.sessionId}`);

      sendSignalUserChanged(session, { accessDenied: true }, Signals.UserChanged);
    });

    publisher.stream.streamManager.on("streamPlaying", (e: StreamManagerEvent) => {
      console.log({ e });

      if (e && e.target instanceof Session) {
        console.log(`[streamPlaying]: ${props.sessionId} : ${e.target.connection.participant.identity}`);
      }
      if (e && e.target instanceof StreamManager) {
        console.log(`[streamPlaying]: ${props.sessionId} : ${e.target.stream?.connection?.data}`);
      }
    });
  }

  function subscribeToSessionEvents(session: Session, publisher: Publisher) {
    session.on("signal:userChanged", (event: SignalEvent) => {
      // TODO: implement controls flow
      console.log({ event });
    });

    session.on("signal:finished", (event: SignalEvent) => {
      try {
        session.disconnect();
        setSessionStatus(SessionStatus.Closed);

        console.log({ event });
      } catch (error) {
        console.warn(error);
      }

      reloadContext(session.sessionId);
    });
  }

  function sendSignalUserChanged(session: Session, data: any, type: any) {
    if (session.isSessionConnected()) {
      const payload = { data: JSON.stringify(data), type: JSON.stringify(type) };
      session.signal(payload);
    }
  }

  useEffect(() => {
    // TODO: (1) react on already connected users
    console.log({ subscribers });
    console.log({ sessionStatus });

    subscribers.length > 0
      ? setSessionStatus(SessionStatus.Active)
      : setSessionStatus(SessionStatus.Awaiting);
  }, [subscribers, sessionStatus]);

  return {
    subscribers,
    sessionStatus,
    setSubscribers,
    subscribeToSessionEvents,
    subscribeToPublisherEvents,
  };
}
