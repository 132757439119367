import { useEffect, useState, useCallback } from "react";
import { BackgroundType } from "shared/enums";
import { IconButton, Grow, CircularProgress, makeStyles } from "@material-ui/core";
import { BlurOn, Block, AddPhotoAlternate, Person } from "@material-ui/icons";
import { deepOrange } from "@material-ui/core/colors";

import "./Buttons.css";

const useStyles = makeStyles(() => ({
  fabProgress: {
    color: deepOrange[500],
    width: "1.6em",
    height: "1.6em",
    position: "absolute",
    zIndex: 1,
  },
}));

type FilterButtonProps = {
  setIsActiveFilter: (active: boolean) => void;
  isActiveFilter: boolean;
  applyVirtualFilter: (options: any, type: BackgroundType) => Promise<void>;
  removeVirtualFilter: () => Promise<void>;
  backgroundOptions: string[];
};

export default function FilterButton({
  setIsActiveFilter,
  isActiveFilter,
  applyVirtualFilter,
  removeVirtualFilter,
  backgroundOptions,
}: FilterButtonProps) {
  const classes = useStyles();
  const [url, setUrl] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const onToggleDialog = useCallback(() => {
    setIsOpenDialog((prev) => !prev);
  }, []);

  const executeFilter = useCallback(
    async (options: any, type: BackgroundType) => {
      try {
        setLoading(true);

        if (isActiveFilter) {
          await removeVirtualFilter();
        }

        await applyVirtualFilter(options, type);
        setIsActiveFilter(true);
      } catch (error) {
        console.error("Error executing filter:", error);
      } finally {
        setLoading(false);
      }
    },
    [isActiveFilter, removeVirtualFilter, applyVirtualFilter, setIsActiveFilter]
  );

  useEffect(() => {
    if (!url && backgroundOptions.length > 0) {
      const defaultUrl = backgroundOptions[0];
      setUrl(defaultUrl);

      // Execute filter for the default background option
      (async () => {
        await executeFilter({ url: defaultUrl }, BackgroundType.Image);
      })();
    }
  }, [url, backgroundOptions, executeFilter]);

  return (
    <div className="filters">
      <IconButton
        title="Image Filter"
        color="primary"
        className="navButton"
        onClick={onToggleDialog}
        style={{ backgroundColor: "orange", width: "1.5em", height: "1.5em" }}
      >
        <Person />
        {loading && <CircularProgress size={48} className={classes.fabProgress} />}
      </IconButton>

      {isOpenDialog && (
        <>
          <Grow in={isOpenDialog} style={{ transformOrigin: "0 0 0" }} {...(isOpenDialog ? { timeout: 400 } : {})}>
            <IconButton
              title="Image Filter"
              color="primary"
              className="navButton"
              disabled={loading}
              style={{ width: "1.5em", height: "1.5em" }}
              onClick={() => executeFilter({ url }, BackgroundType.Image)}
            >
              <AddPhotoAlternate />
            </IconButton>
          </Grow>

          <Grow in={isOpenDialog} style={{ transformOrigin: "0 0 0" }} {...(isOpenDialog ? { timeout: 600 } : {})}>
            <IconButton
              title="Blur Filter"
              color="primary"
              className="navButton"
              disabled={loading}
              style={{ width: "1.5em", height: "1.5em" }}
              onClick={() => executeFilter(undefined, BackgroundType.Blur)}
            >
              <BlurOn />
            </IconButton>
          </Grow>

          <Grow in={isOpenDialog} style={{ transformOrigin: "0 0 0" }} {...(isOpenDialog ? { timeout: 800 } : {})}>
            <IconButton
              color="secondary"
              title="Remove Filter"
              className="navButton"
              style={{ width: "1.5em", height: "1.5em" }}
              onClick={async () => {
                setIsActiveFilter(false);
                await removeVirtualFilter();
              }}
            >
              <Block />
            </IconButton>
          </Grow>
        </>
      )}
    </div>
  );
}
