import ModalDialogComponent from "components/ModalComponent/ModalDialogComponent";
import { finalizeSession, reloadContext } from "services/legacyService";

import { PowerSettingsNew } from "@material-ui/icons";
import { Session } from "openvidu-browser-v2compatibility";

import { Button } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Roles } from "shared/enums";
import { APP_DOMAIN } from "shared/constants";

export default function LeaveButton(props: { session: Session }) {
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const { session } = props;

  useEffect(() => {
    window.addEventListener("message", (event) => {
      if (event.data.type === "CloseSession" && event.origin === APP_DOMAIN!) {
        console.log("Message received from the parent: ", { event, session });

        confirmLeaveSession()
          .then((res) => console.log(res))
          .catch((err) => console.log(err));
      }
    });
  }, []);

  function cancelLeaveSession() {
    setIsOpenDialog(false);
  }

  async function confirmLeaveSession() {
    setIsOpenDialog(false);

    try {
      await finalizeSession(Roles.Analyst, session.sessionId);

      session.signal({
        data: Roles.Analyst,
        type: "finished",
      });
    } catch (error) {
      console.warn(error);
    }

    reloadContext(session.sessionId);
  }

  return (
    <>
      {isOpenDialog ? (
        <ModalDialogComponent
          isOpenDialog={isOpenDialog}
          confirmLeaveSession={confirmLeaveSession}
          cancelLeaveSession={cancelLeaveSession}
        />
      ) : (
        <></>
      )}
      <Button
        color='primary'
        title='FINALIZAR'
        variant='contained'
        className='navButton'
        id='navLeaveButton'
        disabled={isOpenDialog}
        startIcon={<PowerSettingsNew />}
        onClick={() => setIsOpenDialog(true)}
      />
    </>
  );
}
