import { Filter, Publisher } from "openvidu-browser-v2compatibility";
import { BackgroundType } from "shared/enums";
import { useEffect, useState } from "react";

export default function usePublisherFilters(props: { publisher: Publisher }) {
  const { publisher } = props;
  const [activePublisher, setActivePublisher] = useState<Publisher>(publisher);
  const [virtualBackgroundFilter, setVirtualBackgroundFilter] = useState<Filter>();

  // TODO: compare with preset of effect ( before joining conference )
  async function applyVirtualFilter(options: any, type: BackgroundType) {
    console.info({ activePublisher, options, type });

    const virtualBackground = await activePublisher.stream.applyFilter(type, options);

    console.info({ virtualBackground });
    setVirtualBackgroundFilter(virtualBackground);
  }

  async function updateVirtualFilter(url: string) {
    await virtualBackgroundFilter?.execMethod("update", { url });
  }

  async function removeVirtualFilter() {
    setVirtualBackgroundFilter(null!);
    await activePublisher.stream.removeFilter();
  }

  useEffect(() => {
    if (publisher) {
      setActivePublisher(publisher);
    }
  }, [publisher]);

  return {
    applyVirtualFilter,
    updateVirtualFilter,
    removeVirtualFilter,
    virtualBackgroundFilter,
  };
}
