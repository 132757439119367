/* eslint-disable @typescript-eslint/no-empty-function */
import { VideocamOff, Videocam } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { Publisher } from 'openvidu-browser-v2compatibility';

export default function CameraButton(props: {
  // eslint-disable-next-line @typescript-eslint/ban-types
  setIsCamActive: Function;
  publisher: Publisher;
  isCamActive: boolean;
}) {
  function camStatusChanged() {
    props.setIsCamActive(!props.isCamActive);
    props.publisher.publishVideo(!props.isCamActive);
  }

  return (
    <IconButton color="inherit" id="navCamButton" className="navButton" onClick={camStatusChanged}>
      {props.isCamActive ? <Videocam /> : <VideocamOff color="secondary" />}
    </IconButton>
  );
}
