import "./VideoControls.css";
import { BackgroundType } from "shared/enums";
import { Publisher, Session } from "openvidu-browser-v2compatibility";
import { ToolbarComponent } from "../ControlComponent/Toolbar";
import ChatMessageView from "../ChatComponent/ChatMessageComponent";

export default function VideoRoomControls(props: {
  session: Session;
  publisher: Publisher;
  filters: {
    applyVirtualFilter: any;
    removeVirtualFilter: any;
    backgroundOptions: BackgroundType[];
  };
  video: {
    isActiveVideo: boolean;
    setIsActiveVideo: any;
  };
  audio: { isActiveAudio: boolean; setIsActiveAudio: any };
}) {
  return (
    <div className='control-aggregate'>
      <ChatMessageView session={props.session} />
      <ToolbarComponent
        session={props.session}
        publisher={props.publisher}
        activeAudio={props.audio.isActiveAudio}
        activeVideo={props.video.isActiveVideo}
        setIsMicActive={props.audio.setIsActiveAudio}
        setIsCamActive={props.video.setIsActiveVideo}
      />
    </div>
  );
}
