import { Publisher, StreamManager } from "openvidu-browser-v2compatibility";
import React, { useRef, useEffect } from "react";

interface MediaStreamDisplay {
  id?: string;
  title?: string;
  width?: string | number;
  height?: string | number;
  mediaStream: MediaStream | Publisher | StreamManager;
}

export function MediaStreamComponent({ mediaStream }: MediaStreamDisplay) {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoRef.current && mediaStream instanceof StreamManager) {
      mediaStream.addVideoElement(videoRef.current);
    }
    if (videoRef.current && mediaStream instanceof MediaStream) {
      videoRef.current.srcObject = mediaStream;
    }
    if (videoRef.current && mediaStream instanceof Publisher) {
      mediaStream.addVideoElement(videoRef.current);
    }else{
      console.log('MediaStreamComponent:', mediaStream);
    }
  }, [mediaStream, videoRef]);

  return (
    mediaStream && <video autoPlay={true} data-testid='video' key={mediaStream.id} id={mediaStream.id} ref={videoRef} />
  );
}
